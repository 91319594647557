<template>
  <div>
    <div class="row align--center">
      <div class="flex xs12 md6">
        <va-input
          :value="term"
          :placeholder="$t('tables.actions.search')"
          @input="onSearch"
          removable
        >
          <va-icon
            name="fa fa-search"
            slot="prepend"
          />
        </va-input>
      </div>

      <div class="flex xs12 md2">
        <va-select
          v-model="perPage"
          :label="$t('tables.rows')"
          :options="perPageOptions"
          noClear
        />
      </div>

      <slot name="top">
        <div class="flex xs12 md2 offset--md2">
          <va-button
            flat
            small
            color="primary"
            icon="fa fa-plus"
            v-if="editable && top('new')"
            @click.prevent="$emit('new-item')"
          >
            {{ $t('tables.actions.new') }}
          </va-button>
        </div>
      </slot>
    </div>
    <va-data-table
      ref="localtable"
      :fields="columns"
      :data="data"
      :loading="loading"
      :per-page="parseInt(perPage)"
      :no-data-label="$t('tables.empty')"
    >
      <template v-slot:actions="slotProps">
        <slot
          name="row-actions"
          :props="slotProps"
        >
          <va-popover
            v-if="editable && action('view')"
            :message="$t('tables.actions.view')"
            placement="left"
          >
            <va-button
              flat
              small
              color="primary"
              icon="fa fa-search-plus"
              @click.prevent="$emit('view-item', slotProps.rowData)"
            />
          </va-popover>
          <va-popover
            v-if="editable && action('edit')"
            :message="$t('tables.actions.edit')"
            placement="left"
          >
            <va-button
              flat
              small
              color="gray"
              icon="fa fa-edit"
              @click.prevent="$emit('edit-item', slotProps.rowData)"
            />
          </va-popover>
          <va-popover
            v-if="editable && action('delete')"
            :message="$t('tables.actions.delete')"
            placement="left"
          >
            <va-button
              flat
              small
              color="danger"
              icon="fa fa-trash"
              @click.prevent="$emit('delete-item', slotProps.rowData)"
            />
          </va-popover>
          <va-popover
            v-if="editable && action('download') && !!slotProps.rowData.end_date"
            :message="$t('tables.actions.download_certificate')"
            placement="left"
          >
            <va-button
              flat
              small
              color="success"
              icon="fa fa-download"
              @click.prevent="$emit('download-item', slotProps.rowData)"
            />
          </va-popover>
        </slot>
      </template>
    </va-data-table>
  </div>
</template>

<script>
export default {
  name: 'local-table',
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      default: () => [],
    },
    topOptions: {
      type: Array,
      default: () => ['new'],
    },
    actionOptions: {
      type: Array,
      default: () => [
        'view', 'edit', 'delete',
      ],
    },
    editable: {
      type: Boolean,
      default: false,
    },
    crudLinks: {
      type: String,
      default: '',
    },
    controller: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      term: '',
      perPage: '50',
      perPageOptions: ['5', '10', '20', '50'],
      serverParams: {
        perPage: 50,
        page: 1,
      },
    }
  },
  computed: {
    permissionController () {
      let controller = this.controller
      if (controller.length === 0) {
        controller = this.crudLinks
      }
      return this.normalize(controller)
    },
  },
  watch: {
    perPage (val) {
      this.updateParams('perPage', parseInt(val))
    },
    serverParams (val) {
      this.loadItems()
    },
  },
  methods: {
    top (button) {
      return this.topOptions.some(t => t === button)
    },
    action (button) {
      return this.actionOptions.some(a => a === button)
    },
    getRoute (action, props) {
      let params = null
      if (props) {
        params = {
          id: props.id,
        }
      }
      return { name: this.crudLinks + action, params }
    },
    updateParams (property, value) {
      this.serverParams[property] = value
      this.serverParams = { ...this.serverParams }
    },
    onSearch (event) {
      this.$emit('search-data', event)
    },
    onAction (action, data, index) {
      this.$emit(action, data, index)
    },
    loadItems () {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      this.$emit('update-data', this.serverParams)
    },
    capitalize (s) {
      return s.charAt(0).toUpperCase() + s.slice(1)
    },
    normalize (word) {
      let full = ''
      word.split('-').forEach(w => {
        full += this.capitalize(w)
      })
      return full
    },
  },
}
</script>
